import React, { Fragment, useEffect, useState, useRef } from "react";
import { Grid, CircularProgress, DialogActions, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import { Subtext, Subtitle5 } from "../common/CommonStyles/CommonTypographyStyles";
import { connect } from "react-redux";
import axios from "axios";
import base from "../../config";
import {
  StyledDivider,
  StyledSSHKeyIcon,
  UpdateButton,
  StyledHeaderCard,
  StyledHourglass,
  SSHSmallHeader,
  StyledPendingApprovalsGrid,
} from "./SshStyles";
import {
  LargeSubtitle,
  LargeNumber,
} from "../common/CommonStyles/CommonTypographyStyles";
import theme from "../../theme/theme";
import PropTypes from "prop-types";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { DeclineButton, AcceptButton } from "../common/CommonComponents/Buttons";
import QrCode from "./QrCode";
import RegistrationForm from "./SshRegistration/RegistrationForm";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import GenerateKey from "./SshRegistration/GenerateKey";
import { checkIsRegistration } from "../../redux/ssh";

/**
 * SSH Header component of ssh page
 * @author Isabel Sprengers
 * @version 1.1
 * @see Used in component: Ssh.js
 */

const SSHHeader = ({
  lastUpdated,
  pendingData,
  history,
  reload,
  downloadSSHConfigURL,
  // ResData object extraction
  resData,
  isRegistered,
  isApiLoading,
  email,
  idToken,
  isRegistration
}) => {
  const eId = email === undefined ? undefined : email.split("@")[0];
  const enableMFAURL = `${base.urls.apis.sshNew}mfa/enable`;
  const getMFAStatusURL = `${base.urls.apis.sshNew}mfa/status`;
  const getPresignedURL = `${base.urls.apis.doc}/mfa_registration_userguide.pdf`;
  const countOrderValues = (array, id) =>
    array.filter((obj) => obj.status === id).length;
  const draw = (context, link) => {
    const image = new Image();
    image.src = link;
    image.onload = () => {
      context.drawImage(image, 0, 0, 250, 250);
    };
  };
  const [openMFADialog, setOpenMFADialog] = useState(false);
  const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false);
  const [openGenerateKeyDialog, setOpenGenerateKeyDialog] = useState(false);

  const [openMFAConfirmDialog, setOpenMFAConfirmDialog] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mfaData, setMfaData] = useState([]);
  const [initiative, setInitiative] = useState("");

  const l3Url =
    "https://mywizard-aiops.accenture.com/resources/support-requests/";

  const fetchFilePresignedUrl = (event) => {
    event.preventDefault();
    const urls = `${getPresignedURL}?customer_enterprise_id=${eId}`;
    axios.get(urls, options).then((res) => {
      const data = res.data
      window.open(data[0], "_blank");
    }).catch((error) => {
      console.log('Error', error)
    })
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // getMFAStatus();
    checkIsRegistration();
    const interval = setInterval(() => {
      // getMFAStatus();
      checkIsRegistration();
    }, 5000);
    return () => clearInterval(interval);
  }, []);



  const stringToList = (data) => {
    const emergencyScratchCodeArray = data.split(',');
    return emergencyScratchCodeArray.map((item) =>
      <p style={{ lineHeight: "1.5px" }}>{item}</p>
    );
  }

  const handleMFA = (value) => {
    if (value === true) {
      setIsLoading(true)
      const urls = `${enableMFAURL}?userepid=${eId}`;
      axios.get(urls, options).then((res) => {
        setMfaData(res.data)
        setIsLoading(false)
      }).catch((error) => {
        console.log('Error', error)
      })
      setOpenMFADialog(true);
    }
  }

  const handleEnableMFA = () => {
    setIsLoading(true)
    const urls = `${enableMFAURL}?userepid=${eId}`;
    axios.get(urls, options).then((res) => {
      setMfaData(res.data)
      setIsLoading(false)
    }).catch((error) => {
      console.log('Error', error)
    })
    setOpenMFAConfirmDialog(false);
    setOpenMFADialog(true);
  }


  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  return (
    <StyledHeaderCard>
      <Grid container style={{ height: "100%" }}>

        <Grid item xs={12} style={{ height: "100%", padding: "5% 25% 5% 25%" }} >
          {!isApiLoading ?
            <div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
                <StyledSSHKeyIcon />
                <SSHSmallHeader style={{ fontWeight: 450 }}>SSH Setup</SSHSmallHeader>
                <div className={classes.root}>
                  {isRegistration ?
                    <Alert severity="info" style={{ fontSize: "0.9rem" }}>Please note that initial MFA registration setup will take sometime. Please click on 'View MFA Setup' button for more information.</Alert>
                    :
                    <Alert severity="warning" style={{ fontSize: "0.9rem" }}>Your SSH Key isn't registered yet. Please register it to enable access.</Alert>
                  }
                </div>
                {isRegistration ?
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4%"
                  }}>
                    <UpdateButton
                      variant="outlined"
                      onClick={() => setOpenRegistrationDialog(true)}
                      style={{ fontSize: "17px" }}
                    >
                      Update SSH Key
                    </UpdateButton>
                    <UpdateButton
                      variant="outlined"
                      onClick={() => handleEnableMFA(isMFA)}
                      style={{ fontSize: "17px", marginTop: "8%" }}
                    >
                      View MFA Setup
                    </UpdateButton>
                  </div> :
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4%"
                  }}>
                    <UpdateButton
                      variant="outlined"
                      onClick={() => setOpenRegistrationDialog(true)}
                      style={{ fontSize: "17px" }}
                    >
                      Register SSH Key
                    </UpdateButton>
                  </div>
                }
              </div>
              <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "7%"
              }}>
                <Subtitle5>You don't have a key? Please click on 'How to generate SSH Key' below for more details on creating one.</Subtitle5>
                <UpdateButton
                  // variant="outlined"
                  onClick={() => setOpenGenerateKeyDialog(true)}
                  style={{ fontSize: "12px" }}
                >
                  How to generate SSH Key
                </UpdateButton>
              </div>
            </div>
            : <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50}
                style={{ color: `${theme.palette.ssh.main}` }}
              />
            </div>}
        </Grid>

      </Grid>
      {openMFADialog && (
        <CommonDialog
          open={openMFADialog}
          onClose={() => setOpenMFADialog(false)}
          title={"MFA Registration"}
          dialogType={"ssh"}
          dialogContent={
            isLoading ? (
              <DialogContent style={{ padding: "30px 100px" }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={60}
                    style={{ color: `${theme.palette.ssh.main}` }}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "8%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>Please wait.... </h4>
                  <p>Fetching your details.</p>
                  <p style={{ lineHeight: "0.1px" }}><i>This may take up to a minute....</i></p>
                </div>
              </DialogContent>
            ) : (
              <DialogContent>
                {mfaData.map(data => {
                  return (
                    <div>
                      {(() => {
                        if (data.ticketStatus === 'PENDING') {
                          return (
                            <div style={{ padding: "24px 40px" }}>
                              <div
                                style={{
                                  // paddingTop: "3%",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h3 style={{ color: `${theme.palette.ssh.main}` }}>Your registration is in process.</h3>
                                <h4>Please wait.... </h4>
                                <p>Initial setup will take sometime....</p>
                                <p style={{ lineHeight: "0.5px" }}><i> Please check again for status after few minutes. </i></p>
                              </div>
                            </div>
                          )
                        } else if (data.ticketStatus === 'FAILED') {
                          return (
                            <div style={{ padding: "24px 40px" }}>
                              <div
                                style={{
                                  // paddingTop: "3%",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h3 style={{ color: `red` }}>Oops, an error occurred!</h3>
                                <h4>Looks like something went wrong during the MFA setup.</h4>
                                <p>Please reach out to support team by raising a {" "}
                                  <a href={l3Url} target="_blank" rel="noopener noreferrer">
                                    support ticket
                                  </a> to further investigate on this.</p>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <div>
                                <h3><b>Please find the details of your MFA setup</b></h3>
                                <p>Scan the QR code using google authenticator app installed in your mobile OR enter the Secret Key provided.</p>
                                <p>
                                  For further information, please refer this {" "}
                                  <a
                                    variant="body2"
                                    onClick={(e) => fetchFilePresignedUrl(e)}
                                  >
                                    userguide
                                  </a >.
                                </p>
                              </div>
                              <Subtext style={{ textAlign: "initial", color: "red", opacity: "100%", paddingBottom: "3%" }}>
                                Note: Copy and keep the secret key for future use and emergency scratch codes to be used when codes generated in google authenticator app is not working due to time constraint.
                              </Subtext>
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <QrCode draw={draw} height={250} width={250} link={data.s3link} />
                                </div>
                                <p><b>Your secret key is:</b> {data.secretKey} </p>
                                {data.hasOwnProperty("verificationCode") ?
                                  <p><b>Your verification code is:</b> {data.verificationCode}</p> : " "
                                }
                                <p><b>Your emergency scratch codes are:</b> </p>
                                <p>{stringToList(data.emergencyScratchCode)}</p>
                              </div>
                            </div>
                          )
                        }
                      })()}
                    </div>
                  );
                })}
              </DialogContent>
            )

          }
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setOpenMFADialog(false)}
                label={"Close"}
                disabled={false}
              />
            </DialogActions>
          }
        ></CommonDialog>
      )}
      {openMFAConfirmDialog && (
        <CommonDialog
          open={openMFAConfirmDialog}
          onClose={() => setOpenMFAConfirmDialog(false)}
          title={"MFA Registration"}
          dialogType={"ssh"}
          textContent={"Are you sure you want to enable MFA now?"}
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setOpenMFAConfirmDialog(false)}
                label={"No"}
                disabled={false}
              />
              <AcceptButton
                onClick={() => handleEnableMFA()}
                label={"Yes"}
              />
            </DialogActions>
          }
        ></CommonDialog>
      )}
      {openRegistrationDialog && (
        <CommonDialog
          open={openRegistrationDialog}
          onClose={() => setOpenRegistrationDialog(false)}
          title={isRegistration ? "SSH Key Update" : "SSH Key Registration"}
          dialogType={"ssh"}
          textContent={<RegistrationForm />}
        ></CommonDialog>
      )}
      {openGenerateKeyDialog && (
        <CommonDialog
          open={openGenerateKeyDialog}
          onClose={() => setOpenGenerateKeyDialog(false)}
          title="How to generate SSH Key"
          dialogType={"ssh"}
          textContent={<GenerateKey />}
        ></CommonDialog>
      )}
    </StyledHeaderCard>
  );
};

const mapStateToProps = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  activeAccount: state.auth.activeAccount,
  isRegistration: state.home.isRegistration,
  isApiLoading: state.home.isLoading
});

SSHHeader.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Contains date of last time ssh was updated
   */
  lastUpdated: PropTypes.string,
  /**
   * Contains data of all pending requests
   */
  pendingData: PropTypes.array,
  /**
   * Contains data of all requests
   */
  idToken: PropTypes.object,
  /**
   * Boolean that is used to trigger when a reload should be done
   */
  isRegistration: PropTypes.bool,
  /**
  * Boolean that is used to trigger when a reload should be done
  */
  isLoading: PropTypes.bool,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  downloadSSHConfigURL: PropTypes.string,

  isRegistration: PropTypes.bool,
};

export default connect(mapStateToProps)(SSHHeader);

