import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Graphik');
    body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: Graphik;
        color: #000000
    }
`;

export default GlobalStyles;
