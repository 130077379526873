import React from "react";
import {
  ApproveButton,
  DenyButton,
  SmallButton,
  StyledLongButton,
} from "../CommonStyles/CommonButtons";
/**
 * Common Accept BUtton
 */
export const AcceptButton = ({ onClick, className, disabled, label }) => {
  return (
    <ApproveButton
      variant="outlined"
      onClick={onClick}
      className={className}
      style={{
        fontSize: "16px",
        fontWeight: "bold",
      }}
      disabled={disabled}
    >
      {label}
    </ApproveButton>
  );
};
/**
 * Common Decline BUtton
 */
export const DeclineButton = (props) => {
  return (
    <DenyButton
      variant="outlined"
      onClick={props.onClick}
      className={props.className}
      style={{
        fontSize: "16px",
        fontWeight: "bold",
        ...(props.marginLeftOn && { marginLeft: props.marginLeftOn }),
      }}
      disabled={props.disabled}
    >
      {props.label}
    </DenyButton>
  );
};
/**
 * Common Borderless Button
 */
export const BorderlessButton = (props) => {
  return (
    <SmallButton
      onClick={props.onClick}
      className={props.className}
      startIcon={props.startIcon}
      href={props.href}
      disabled={props.disabled}
    >
      {props.label}
    </SmallButton>
  );
};
/**
 * Common Secondary Button
 */
export const SecondaryButton = (props) => {
  return (
    <SmallButton
      onClick={props.onClick}
      className={props.className}
      variant="outlined"
      href={props.href}
      disabled={props.disabled}
      placeholder={props.placeholder}
    >
      {props.label}
    </SmallButton>
  );
};
/**
 * Common Primary BUtton
 */
export const PrimaryButton = (props) => {
  return (
    <SmallButton
      onClick={props.onClick}
      className={props.className}
      variant="contained"
    >
      {props.label}
    </SmallButton>
  );
};
/**
 * Common Long Secondary Button (mostly used for Edit and Set)
 */
export const LongSecondaryButton = (props) => {
  return (
    <StyledLongButton
      onClick={props.onClick}
      className={props.className}
      variant="outlined"
      disabled={props.disabled}
    >
      {props.label}
    </StyledLongButton>
  );
};
