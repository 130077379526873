import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import sshReducer from "./ssh";
import homeReducer from "./home";
import { connectRouter } from "connected-react-router";
import faqReducer from "./faq/index";
const rootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    ssh: sshReducer,
    home: homeReducer,
    router: connectRouter(history),
    faq: faqReducer,
  });

export default rootReducer;
