//React and high level app based imports
import React from "react";
import ReactDOM from "react-dom";
import { Offline, Online } from "react-detect-offline";

// Redux related imports
import { applyMiddleware, createStore } from "redux"; //functions in redux state store for creating the state and applying middleware to it
import { Provider } from "react-redux"; //redux with react HOC
import thunkMiddleware from "redux-thunk"; //middleware for async redux actions
import rootReducer from "./redux"; //the redux store state entrypoint
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

// Router related imports
import Routes from "./routes/routes"; //acts like "Main"
import Error from "./components/error/Error"; //error page for no internet
//Logging
import { composeWithDevTools } from "redux-devtools-extension";
// Style related imports
import JssProvider from "react-jss/lib/JssProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "./theme/globalStyle";
import theme from "./theme/theme";

export const history = createBrowserHistory();
//initialise state & handle cookies
const initialState = {};

(async () => {
  //creating the redux store
  // Without middleware, Redux store only supports synchronous data flow.
  // This is what you get by default with createStore().
  const composedEnhancer = (history) =>
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunkMiddleware)
    );
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancer(history)
  );
  // Redux will be the source of truth for your data and
  // React Router will be the source of truth for your URL

  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <JssProvider classNamePrefix={"mycloud"}>
          <div>
            <Online polling={{ enabled: false }}>
              <Routes />
            </Online>
            <Offline polling={{ enabled: false }}>
              <Error />
            </Offline>
          </div>
        </JssProvider>
      </MuiThemeProvider>
    </Provider>,
    document.querySelector("#root")
  );
})();

/*
This file:
  - Initialises redux store
  - Inititalises router
  - Adds a theme provider
*/

// import * as serviceWorker from './serviceWorker';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
