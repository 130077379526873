import React, { useEffect, useState } from "react";

// redux
import { history } from "../index";
import { connect } from "react-redux";
import { checkIsRegistration } from "../redux/ssh";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route, withRouter } from "react-router-dom";
import { getAuthorizationPermissions } from "../redux/auth/authActions";

// Components
import App from "../components/home/App";
import PrivateRoute from "./privateRoute";
import Login from "../components/auth/Login";
import AuthPage from "../components/auth/AuthPage";
import Error404 from "../components/error/Error404";
import CommonLayout from "../components/common/ComonLayout/CommonLayout";


const Routes = ({
  exp,
  email,
  isAuthenticated,
  checkIsRegistration,
  adRole,
  getAuthorizationPermissions,
  camPermissions,
}) => {
  const userRbac = JSON.parse(
    localStorage.getItem("userRoleBasedAccessControl")
  );


  const rbacFetchNotNeeded = userRbac !== null ? true : false;

  const [fetchedRBAC, setFetchedRBAC] = useState(rbacFetchNotNeeded);

  const currentCamPermissions =
    userRbac !== null ? userRbac.data : camPermissions;  

  
  const auth = localStorage.getItem("isRegistered");
  const idToken = localStorage.getItem("token");

  useEffect(() => {
    exp && localStorage.setItem("exp", exp);
  }, [exp]);

  useEffect(() => {
    if (email) {
      checkIsRegistration(email, idToken);
      // if (adRole && !fetchedRBAC) {
      //   getAuthorizationPermissions(email, adRole, idToken);
      //   setFetchedRBAC(true);
      // }
    }
  }, [email, auth]);

  return (
    <ConnectedRouter history={history}>
      <CommonLayout history={history} camPermissions={currentCamPermissions}>
        <Switch>
          <PrivateRoute
            path="/"
            exact
            isAuthenticated={isAuthenticated}
            component={App}
          />
          <Route path="/login" component={withRouter(Login)} />
          <Route path="/404" component={Error404} />
          <Route exact path="/auth" component={AuthPage} />
        </Switch>
      </CommonLayout>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    exp: state.auth.userDetails.exp,
    iat: state.auth.userDetails.iat,
    timeout: state.auth.timeout,
    email: state.auth.userDetails.email,
    adRole: state.auth.userDetails.profile,
    camPermissions: state.auth.camPermissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkIsRegistration: (email, idToken) => dispatch(checkIsRegistration(email, idToken)),
  getAuthorizationPermissions: (email, adRole, idToken) =>
    dispatch(getAuthorizationPermissions(email, adRole, idToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);