import styled from "styled-components";
import { IconButton, Badge, Fab } from "@material-ui/core";

export const AccountWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledAccountButton = styled(IconButton)`
  &&&&& {
    color: ${(props) => (props.selected ? "#07b059" : "white")};
    font-family: Graphik;
    text-decoration: none;
    color: #fff;
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.16);
      border-radius: 0px;
    }
    font-size: inherit;
    // padding: 8px 8px 8px 8px;
    background-color: "transparent";
  }
`;

export const StyledFab = styled(Fab)`
  &&& {
    width: 34px;
    min-width: 34px;
    height: 34px;
    min-height: 34px;
  }
`;

export const StyledBadge = styled(Badge)`
  &&& {
    span:nth-child(2) {
      background: red;
    }
  }
`;

export const UserInfoWrapper = styled.div`
  display: block;
`;

export const StyledUserNameWrapper = styled.div`
  height: 15px;
`;

export const StyledEmailWrapper = styled.div`
  font-size: 12px;
`;
