import update from "immutability-helper";
import { headers } from "../../helper/api";
import base from "../../config";
import axios from "axios";

//Action
export const PRESIGNED_URL = "PRESIGNED_URL";
export const SSH_CONFIG_URL = "SSH_CONFIG_URL";

export const START_LOADING = "START_LOADING";
export const ASYNC_ERROR = "ASYNC_ERROR";

//URLs

//Reducer
const initState = {
  presignedUrls: [],
  isLoading: false,
  sshConfig: "",
};

export default function reducer(state = initState, action) {
  const data = action.data;
  switch (action.type) {
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case PRESIGNED_URL:
      const current_dict = state.presignedUrls;
      current_dict[data[1]] = data[0];
      return update(state, {
        presignedUrls: {
          $set: current_dict,
        },
        isLoading: { $set: false },
      });
    case SSH_CONFIG_URL:
      return { ...state, sshConfig: action.data };
    default:
      return state;
  }
}

//Action Creator

const sshConfigUrl = `${base.urls.apis.doc}`;


export const fetchPresignedUrl = (email, fileName) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });

  //let url = `${fetchEc2DataUrl}?customer_enterprise_id=${customerEnterpriseId}`;
  if (customerEnterpriseId && idToken) {
    return axios
      .get(
        `${base.urls.apis.doc}` +
          `/${fileName}?customer_enterprise_id=${customerEnterpriseId}&inititive=${activeAccount}`,
        {
          headers: { ...headers, Authorization: idToken },
        }
      )
      .then((res) => {
        dispatch({ type: PRESIGNED_URL, data: res.data });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const fetchSSHConfigUrl = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });

  if (customerEnterpriseId && idToken) {
    return axios
      .get(
        `${base.urls.apis.doc}` +
          `/ssh_config.txt?customer_enterprise_id=${customerEnterpriseId}&inititive=${activeAccount}`,
        {
          headers: { ...headers, Authorization: idToken },
        }
      )
      .then((res) => {
        dispatch({ type: SSH_CONFIG_URL, data: res.data });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

