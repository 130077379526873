import React, { useEffect, useState } from "react";
import {
  Grid,
  Zoom,
  Divider,
  //   Tooltip,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useNavbarStyles } from "../makeStylesMaterialUi/NavbarMakeStyles";
import { images } from "../../../assets";
import { connect } from "react-redux";
import {
  StyledPaperElevation,
  StyledListItem,
  StyledListCloudOptionsPosition,
  StyledTitle,
  GridScreenNameNavbar,
} from "../CommonSidebar/StyledCommonSidebar";
import {
  //   StyledFab,
  //   StyledBadge,
  AccountWrapper,
  UserInfoWrapper,
  StyledEmailWrapper,
  StyledAccountButton,
  StyledUserNameWrapper,
} from "./StyledNavbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge } from "@material-ui/core";
import { styled } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { UpdateButton } from "../../ssh/SshStyles";
import { DeclineButton } from "../CommonComponents/Buttons";
import { CSVLink } from "react-csv";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "#E90D0D",
    backgroundColor: "#E90D0D",
    padding: "0px",
    height: "10px",
    minWidth: "10px"
  }
});

const Navbar = ({
  email,
  screenName,
  handleCloudAction,
  initialsUserName,
  userName,
  adGroup,
  activeAccount,
}) => {
  /**
   * Navigation Bar
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: CommonLayout.js
   */

  let classes = useNavbarStyles();
  useEffect(() => { }, [activeAccount]);

  const [smallScreen, setSmallScreen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [gridSmallSizeClouds, setGridSmallSizeClouds] = useState(9);
  const [gridSmallSizeIcons, setGridSmallSizeIcons] = useState(3);

  const open = Boolean(anchorEl);

  const [modalStyle] = React.useState(getModalStyle);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [data, setData] = useState([]);

  const handleScreenSizeUpdate = () => {
    setSmallScreen(window.innerWidth < 960 ? false : true);
    setGridSmallSizeClouds(window.innerWidth < 750 ? 8 : 9);
    setGridSmallSizeIcons(window.innerWidth < 750 ? 4 : 3);
  };

  useEffect(() => {
    handleScreenSizeUpdate();
    const callback = () => {
      handleScreenSizeUpdate();
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  const handleShowClouds = (cloud) => {
    if (cloud) {
      let changeCloudsState = {
        all: cloud === "all" ? true : false,
        aws: cloud === "aws" ? true : false,
        azure: cloud === "azure" ? true : false,
        gcp: cloud === "gcp" ? true : false,
      };
      handleCloudAction(changeCloudsState);
      setChecked((prev) => !prev);
    }
  };

  const styleUserMenu = {
    marginTop: "1.7em",
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    window.location.href = process.env.REACT_APP_LOGIN_URL;
    localStorage.clear();
    localStorage.removeItem("user");
  };


  const headers = [
    { label: "User name", key: "arn" },
    { label: "Access key ID", key: "aws_access_key_id" },
    { label: "Security access key", key: "aws_secret_access_key" }
  ];

  const handleCloseNotifications = () => {
    setOpenNotifications(false);
  };

  const handleNotifications = (event) => {
    setOpenNotifications(true);
  };

  const handleDownloadKeys = (row) => {
    let selectedData = [
      {
        "arn": row.arn,
        "aws_access_key_id": row.aws_access_key_id,
        "aws_secret_access_key": row.aws_secret_access_key
      }
    ];
    setData(selectedData);
  };


  return (
    <div className={classes.navbar_size}>
      <div className={classes.allHeight}>
        {smallScreen && (
          <div className={classes.navbar_logo}>
            <img alt="" src={images.NavLogoImage} width="auto" />
          </div>
        )}
      </div>
      <Divider orientation="vertical" />
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container className={classes.allHeight}>
            <GridScreenNameNavbar item lg={5} md={4} xs={4}>
              <StyledTitle>{screenName}</StyledTitle>
            </GridScreenNameNavbar>
            <Grid item lg={7} md={8} xs={8}>
              <Grid container className={classes.allHeight}>
                <Grid item lg={12} md={12} xs={gridSmallSizeClouds}>
                  {checked ? (
                    <Grid container className={classes.allHeight}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        className={classes.allHeight}
                      >
                        <Grid
                          container
                          className={classes.alignSimpleCloudTarget}
                        >
                          <Zoom
                            in={checked}
                            style={{
                              transitionDelay: checked ? "170ms" : "0ms",
                            }}
                          >
                            <Grid
                              item
                              lg={3}
                              md={3}
                              xs={3}
                              onClick={() => handleShowClouds("gcp")}
                            >
                              <StyledPaperElevation elevation={5}>
                                <StyledListItem button>
                                  <StyledListCloudOptionsPosition>
                                    <img
                                      alt=""
                                      src={images.GCPCloudIcon}
                                      width="33em"
                                    />
                                  </StyledListCloudOptionsPosition>
                                </StyledListItem>
                              </StyledPaperElevation>
                            </Grid>
                          </Zoom>
                          <Zoom
                            in={checked}
                            style={{
                              transitionDelay: checked ? "120ms" : "0ms",
                            }}
                          >
                            <Grid
                              item
                              lg={3}
                              md={3}
                              xs={3}
                              onClick={() => handleShowClouds("azure")}
                            >
                              <StyledPaperElevation elevation={5}>
                                <StyledListItem button>
                                  <StyledListCloudOptionsPosition>
                                    <img
                                      alt=""
                                      src={images.AzureCloudIcon}
                                      width="33em"
                                    />
                                  </StyledListCloudOptionsPosition>
                                </StyledListItem>
                              </StyledPaperElevation>
                            </Grid>
                          </Zoom>
                          <Zoom
                            in={checked}
                            style={{
                              transitionDelay: checked ? "70ms" : "0ms",
                            }}
                          >
                            <Grid
                              item
                              lg={3}
                              md={3}
                              xs={3}
                              onClick={() => handleShowClouds("aws")}
                            >
                              <StyledPaperElevation elevation={5}>
                                <StyledListItem button>
                                  <StyledListCloudOptionsPosition>
                                    <img
                                      alt=""
                                      src={images.AwsCloudIcon}
                                      width="33em"
                                    />
                                  </StyledListCloudOptionsPosition>
                                </StyledListItem>
                              </StyledPaperElevation>
                            </Grid>
                          </Zoom>
                          <Zoom in={checked}>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              xs={3}
                              onClick={() => handleShowClouds("all")}
                            >
                              <StyledPaperElevation elevation={5}>
                                <StyledListItem button>
                                  <StyledListCloudOptionsPosition>
                                    <img
                                      alt=""
                                      src={images.AllCloudIcon}
                                      width="33em"
                                    />
                                  </StyledListCloudOptionsPosition>
                                </StyledListItem>
                              </StyledPaperElevation>
                            </Grid>
                          </Zoom>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container className={classes.allHeight}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        className={classes.allHeight}
                      >
                        <Grid
                          container
                          className={classes.alignSimpleCloudTarget}
                        ></Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  lg={10}
                  md={2}
                  xs={gridSmallSizeIcons}
                  style={{ display: "flex" }}
                >
                </Grid>
                <Grid
                  item
                  lg={1}
                  md={2}
                  xs={gridSmallSizeIcons}
                  style={{ display: "flex" }}
                >
                  <Grid container>
                    <AccountWrapper onClick={handleMenu}>
                      <StyledAccountButton style={{ color: "black", fontWeight: "400" }}>
                        {initialsUserName.substring(0, 1) +
                          "." +
                          initialsUserName.substring(1, 2)}{" "}
                        <AccountCircle />
                      </StyledAccountButton>
                    </AccountWrapper>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={open}
                      onClose={handleClose}
                      style={styleUserMenu}
                    >
                      <MenuItem onClick={handleClose}>
                        <UserInfoWrapper>
                          <StyledUserNameWrapper>
                            {userName}
                          </StyledUserNameWrapper>
                          <div><p></p></div>
                          <StyledEmailWrapper>{email}</StyledEmailWrapper>
                        </UserInfoWrapper>
                      </MenuItem>
                      <MenuItem onClick={handleLogout} style={{ fontWeight: 500}}>Logout</MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
     
    </div>
  );
};

const mapStateToProps = (state) => ({
  idToken: state.auth.idToken,
  email: state.auth.userDetails.email ? state.auth.userDetails.email : "",
  initialsUserName: state.auth.userDetails.email
    ? state.auth.userDetails.email
      .replace(/@[^@]+$/, "")
      .replace(".", " ")
      .split(" ")
      .map(function (item) {
        return item[0];
      })
      .join("")
      .toUpperCase()
    : "",
  userName: state.auth.userDetails.email
    ? state.auth.userDetails.email.split("@")[0].replace(".", " ")
    : "",
  adGroup: state.auth.userDetails.profile,
  activeAccount: state.auth.activeAccount,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
