import React, { useEffect, Fragment } from "react";
import { Footer } from "../common/Footer";
import RegistrationForm from "../ssh/SshRegistration/RegistrationForm";
import SSHHeader from "../ssh/SSHHeader";

/**
 * The dashboard page
 * @author shilpa.adikeshava
 * @version 1.0
 * @see Used in component: N/A
 */

const App = () => {
  return (
    <div>
      <SSHHeader />
      {/* <RegistrationForm /> */}
      <Footer />
    </div>
  );
};

export default App;