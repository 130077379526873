import update from "immutability-helper";
import { headers } from "../../helper/api";
import base from "../../config";
import axios from "axios";

//Action
export const START_LOADING = "home/START_LOADING";

export const CHECK_HAS_REGISTERED = "home/CHECK_HAS_REGISTERED";
export const CHECK_HAS_NOT_REGISTERED = "home/CHECK_HAS_NOT_REGISTERED";

export const ASYNC_ERROR = "home/ASYNC_ERROR";


const checkIsRegistrationUrl = `${base.urls.apis.sshNew}`;



//Reducer
const initState = {
  isSshLoading: true,
  isRegistrationLoading: true,
  isRegistration: false,
  error: {},
};

export default function reducer(state = initState, action) {
  const data = action.data;
  switch (action.type) {
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case CHECK_HAS_REGISTERED:
      if (action.type === "home/CHECK_HAS_REGISTERED") {
        return update(state, {
          isLoading: { $set: false },
          isRegistration: { $set: action.hasRegistered },
          isRegistrationLoading: { $set: false },
        });
      }
      return { ...state };
    case ASYNC_ERROR:
      if (action.type === "home/ASYNC_ERROR") {
        return update(state, {
          error: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state };
    case "HANDLE_IS_SSH_LOADING":
      if (action.type === "HANDLE_IS_SSH_LOADING") {
        return { ...state, isSshLoading: false };
      }
      return { ...state };
    default:
      return state;
  }
}

//Action Creator

export const checkIsRegistration = (eid, idToken) => (dispatch, getState) => {
  //const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const email = eid;
  dispatch({ type: START_LOADING });
  return axios
    .get(checkIsRegistrationUrl, {
      headers: { ...headers, Authorization: idToken },
      params: { email: email, inititive: activeAccount },
    })
    .then((res) => {
      // const hasRegistered = res.data === "true";
      // const hasNotRegistered = res.data === "false";
      dispatch({ type: CHECK_HAS_REGISTERED, hasRegistered: res.data });
      // hasNotRegistered && dispatch({ type: CHECK_HAS_NOT_REGISTERED });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

