import React, { Fragment } from "react";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

/**
 * This component is visible when you click the "submit" button on registration or update Key
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: RegistrationForm.js
 */

const SuccessfulOrFailedDialog = ({ history, open, booleanTitle, closeSuccessOrFailureDialog, isRegistration, initiative }) => {

  const closeDialog = () => {
    if (booleanTitle) {
      window.location.reload(false);
    }
    closeSuccessOrFailureDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-delete"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle style={{ backgroundColor: "#000000", color: "white" }}>
        {booleanTitle ? 'Successful' : 'Failed'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            booleanTitle ?
              <Fragment>
                {/* {`Your key has been successfully ${isRegistration ? 'updated' : 'registered'}.`}<br></br> */}
                { isRegistration ? 
                  <div>
                    <p>Your key has been successfully updated.</p>
                  </div>
                 :
                  <div>
                    <p><b>Your key has been successfully registered.</b></p>
                      <div>
                        <p><b>Your MFA registration is in process.</b></p>
                        <p>Initial setup will take sometime.</p>
                        <p>To check for status please click on 'VIEW MFA SETUP' button after few minutes.</p>
                      </div> 
                  </div>
                }
              </Fragment>
              :
              <div>
                {`There was an error ${isRegistration ? 'updating your key. Please make sure that you are updating the key in proper format and not entering the same key again.' : 'registering your key. Please make sure that you are registering the key in proper format.'}`}
                For further support , please log a Service Request with <b><a href="https://mywizard-aiops.accenture.com/resources/support-requests">MyWizardAiOps Support Team</a></b> under application "myWizard AiOps".
              </div>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="confirmDialogButton-cy"
          variant="outlined"
          onClick={closeDialog}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SuccessfulOrFailedDialog.propTypes = {
  open: PropTypes.bool,
  booleanTitle: PropTypes.bool,
  closeSuccessOrFailureDialog: PropTypes.func,
  isRegistration: PropTypes.bool,
};

export default SuccessfulOrFailedDialog;
