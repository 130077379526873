import update from "immutability-helper";
import { headers } from "../../helper/api";
import base from "../../config";
import axios from "axios";

//Action
export const START_LOADING = "ssh/START_LOADING";
export const ASYNC_ERROR = "ssh/ASYNC_ERROR";
export const REGISTRATION_SUCCESS = "ssh/REGISTRATION_SUCCESS";
export const CHECK_HAS_REGISTERED = "ssh/CHECK_HAS_REGISTERED";
export const CHECK_HAS_NOT_REGISTERED = "ssh/CHECK_HAS_NOT_REGISTERED";
export const PRIVATE_DNS_VALID = "ssh/PRIVATE_DNS_VALID";
export const PRIVATE_DNS_UNVALID = "ssh/PRIVATE_DNS_UNVALID";
export const START_SUBMIT_LOADING = "ssh/START_SUBMIT_LOADING";
export const SSH_MANAGEMENT_EMPTY_STRING = "ssh/SSH_MANAGEMENT_EMPTY_STRING";
export const MFA_DATA = "ssh/MFA_DATA";

// Action Types for the validations for give ssh acces for an user.
export const CHECK_USER_START_LOADING_ICON =
  "ssh/CHECK_USER_START_LOADING_ICON";
export const IS_USER_EXIST_IN_DB = "ssh/IS_USER_EXIST_IN_DB";
export const DISABLED_CHIT_BUTTON = "ssh/DISABLED_CHIT_BUTTON";

export const RESET_VALIDATION = "ssh/RESET_VALIDATION";
export const SUCCESS_OR_FAILURE_DIALOG = "ssh/SUCCESS_OR_FAILURE_DIALOG";
export const SAVE_SSH_PUBLIC_KEY = "ssh/SAVE_SSH_PUBLIC_KEY";
export const IS_LOADING_SSH_MANAGEMENT_DIALOG =
  "ssh/IS_LOADING_SSH_MANAGEMENT_DIALOG";

//URLs
const submitRegistrationUrl = `${base.urls.apis.sshNew}`;
const checkIsRegistrationUrl = `${base.urls.apis.sshNew}`;
const enableMFAURL = `${base.urls.apis.sshNew}mfa/enable`;

//Initial State
const initState = {
  isLoading: false,
  isSubmitLoading: false,
  isRegistrationSuccessful: false,
  openSuccessfulOrFailedDialog: false,
  isLoadingSshManagementDialog: false,
  isRegistration: false,
  isPrivateDnsValid: true,
  isAccessSuccessful: false,
  hasSubmitted: false,
  error: {},
  isPendingLoading: [],
  ec2Instances: [],
  isSshManagementLoading: true,
  // validations for give ssh acces for an user.
  checkUserStartLoadingIcon: false,
  isAddUserIconDisabled: true,
  isUserHaveNotSshKey: undefined,
  apiError: false,
  currentPublicKey: "Loading",
  mfaData: [],
};

//Reducer
export default function reducer(state = initState, action) {
  switch (action.type) {
    case REGISTRATION_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        isRegistrationSuccessful: { $set: action.registrationValue },
        openSuccessfulOrFailedDialog: { $set: true },
      });
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case START_SUBMIT_LOADING:
      return update(state, { isSubmitLoading: { $set: true } });
    case CHECK_HAS_REGISTERED:
      return update(state, {
        isLoading: { $set: false },
        isRegistration: { $set: true },
      });
    case CHECK_HAS_NOT_REGISTERED:
      return update(state, {
        isLoading: { $set: false },
        isRegistration: { $set: false },
      });
    case PRIVATE_DNS_VALID:
      return update(state, {
        isSubmitLoading: { $set: false },
        isPrivateDnsValid: { $set: true },
        isAccessSuccessful: { $set: true },
        hasSubmitted: { $set: true },
      });
    case PRIVATE_DNS_UNVALID:
      return update(state, {
        isSubmitLoading: { $set: false },
        isPrivateDnsValid: { $set: false },
        isAccessSuccessful: { $set: false },
        hasSubmitted: { $set: true },
      });

    case IS_LOADING_SSH_MANAGEMENT_DIALOG:
      return update(state, {
        isLoadingSshManagementDialog: { $set: action.data },
      });
    
    case CHECK_USER_START_LOADING_ICON:
      return update(state, { checkUserStartLoadingIcon: { $set: true } });
    case IS_USER_EXIST_IN_DB:
      if (action.data) {
        return {
          ...state,
          checkUserStartLoadingIcon: false,
          isAddUserIconDisabled: false,
          isUserHaveNotSshKey: undefined,
          apiError: false,
        };
      } else {
        if (action.apiError) {
          return {
            ...state,
            checkUserStartLoadingIcon: false,
            isAddUserIconDisabled: true,
            isUserHaveNotSshKey: undefined,
            apiError: true,
          };
        } else {
          return {
            ...state,
            checkUserStartLoadingIcon: false,
            isAddUserIconDisabled: true,
            isUserHaveNotSshKey: true,
            apiError: false,
          };
        }
      }
    case DISABLED_CHIT_BUTTON:
      return { ...state, isAddUserIconDisabled: true };
    case SSH_MANAGEMENT_EMPTY_STRING:
      return {
        ...state,
        checkUserStartLoadingIcon: false,
        isAddUserIconDisabled: true,
        isUserHaveNotSshKey: undefined,
        apiError: undefined,
      };
    case RESET_VALIDATION:
      return {
        ...state,
        checkUserStartLoadingIcon: true,
        isUserHaveNotSshKey: undefined,
      };
    case SUCCESS_OR_FAILURE_DIALOG:
      return {
        ...state,
        openSuccessfulOrFailedDialog: false,
        isSubmitLoading: false,
      };
    case SAVE_SSH_PUBLIC_KEY:
      return {
        ...state,
        currentPublicKey: action.pubKey,
      };
    case ASYNC_ERROR:
      return update(state, {
        error: { $set: action.data },
        isLoading: { $set: false },
        isPrivateDnsValid: { $set: true },
        isRegistrationSuccessful: { $set: false },
        isAccessSuccessful: { $set: false },
        isSubmitLoading: { $set: false },
      });
    case MFA_DATA:
      return update(state, {
        mfaData: { $set: action.data },
      });
    case "@@router/LOCATION_CHANGE":
      return { ...initState, isRegistration: state.isRegistration };
    default:
      return state;
  }
}

// Action Creator

export const submitRegistration = (data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: START_SUBMIT_LOADING });
  const submitData = email ? { ...data, email: email } : { ...data };
  axios
    .post(submitRegistrationUrl, submitData, {
      headers: { ...headers, Authorization: idToken },
      params: {
        email: email,
        public_key: data.public_key,
        last_name: data.last_name,
        first_name: data.first_name,
        team: data.team,
        inititive: activeAccount,
        passphrase: data.passphrase
      },
    })
    .then((res) => {
      dispatch({ type: REGISTRATION_SUCCESS, registrationValue: true });
    })
    .catch((err) => {
      console.log("Async error", err);
      dispatch({ type: REGISTRATION_SUCCESS, registrationValue: false });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const submitSSHUpdate = (data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: START_SUBMIT_LOADING });
  const submitData = email ? { ...data, email: email } : { ...data };
  axios
    .put(submitRegistrationUrl, submitData, {
      headers: { ...headers, Authorization: idToken },
      params: {
        email: email,
        public_key: data.public_key,
        inititive: activeAccount,
        passphrase: data.passphrase
      },
    })
    .then((res) => {
      dispatch({ type: REGISTRATION_SUCCESS, registrationValue: true });
    })
    .catch((err) => {
      console.log("Async error", err);
      dispatch({ type: REGISTRATION_SUCCESS, registrationValue: false });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};


export const checkIsRegistration = (eid, idToken) => (dispatch, getState) => {
  //const idToken = localStorage.getItem("token");
  const email = eid;
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: START_LOADING });
  axios
    .get(checkIsRegistrationUrl, {
      headers: { ...headers, Authorization: idToken },
      params: { email: email, inititive: activeAccount },
    })
    .then((res) => {
      if (res.data === "true") {
        dispatch({ type: CHECK_HAS_REGISTERED });
        localStorage.setItem("isRegistered", true);
      } else {
        dispatch({ type: CHECK_HAS_NOT_REGISTERED });
        localStorage.setItem("isRegistered", false);
      }
    })
    .catch((err) => {
      console.log("Async error", err);
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};


export const enableMFA = (eid) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: START_LOADING });
  axios
    .get(enableMFAURL, {
      headers: { ...headers, Authorization: idToken },
      params: { userepid: eid, inititive: activeAccount },
    })
    .then((res) => {
      dispatch({ type: MFA_DATA, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const sshManagement_DebounceEmptyString = () => ({
  type: SSH_MANAGEMENT_EMPTY_STRING,
});

export const disabledChipButtonAction = () => ({
  type: DISABLED_CHIT_BUTTON,
});

export const resetValidation = () => ({
  type: RESET_VALIDATION,
});

export const checkUserExistAction = (data) => ({
  type: IS_USER_EXIST_IN_DB,
  data,
});

export const closeSuccessOrFailureDialog = () => ({
  type: SUCCESS_OR_FAILURE_DIALOG,
});

export const saveSshPublicKey = (pubKey) => {
  return {
    type: SAVE_SSH_PUBLIC_KEY,
    pubKey,
  };
};
export const isLoadingSshManagementDialogAction = (isLoading) => ({
  type: IS_LOADING_SSH_MANAGEMENT_DIALOG,
  data: isLoading,
});
