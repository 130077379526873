import { makeStyles } from "@material-ui/core";

export const useSSHMakeStyles = makeStyles((theme) => ({
  TextFieldNotes: {
    padding: "10.5px 14px",
  },
  PendingApprovalsApproveButton: {
    height: "45px",
    right: "75px",
    top: "85px",
    width: "140px",
    "@media (max-width: 1300px)": {
      top: "0px",
      right: "0px",
      padding: "10px",
      marginRight: "10px",
    },
  },
  PendingApprovalsDeclineButton: {
    height: "45px",
    right: "75px",
    top: "90px",
    width: "140px",

    "@media (max-width: 1300px)": {
      top: "0px",
      right: "0px",
      padding: "10px",
      marginRight: "10px",
    },
  },
  SeeArchiveButton: {
    margin: "1%",
    left: "45%",
    color: theme.palette.ssh.main,
    fontSize: "15px",
  },
  SSHConfigButton: {
    width: "auto",
    margin: "auto",
    bottom: "5px",
    color: theme.palette.ssh.main,
    fontSize: "15px",
  },
  RequestAccessButton: {
    marginTop: "9px",
    color: theme.palette.ssh.main,
    fontSize: "15px",
  },
  UpdateSSHKey: {
    marginTop: "9px",
    color: theme.palette.ssh.main,
    borderColor: theme.palette.ssh.main,
    fontSize: "15px",
  },
  UpdateRegKey: {
    margin: "auto",
    display: "flex",
    color: theme.palette.ssh.main,
    borderColor: theme.palette.ssh.main,
    fontSize: "15px",
  },
  SSHSecondaryButton: {
    color: theme.palette.ssh.main,
    borderColor: theme.palette.ssh.main,
    margin: "2px",
  },
}));
