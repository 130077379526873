import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Graphik',
  },
  palette: {
    primary: {
      //aqua
      light: `${grey[500]}`,
      main: "#7500C0",
      dark: "#A100FF",
      contrastText: "#fff",
    },
    secondary: {
      //green - Accenture technology green - #03f94f, blue- #69eff8
      //Edited to match shades of Accenture technology green. Will be primary color
      light: "#03f94f",
      main: "#A100FF",
      dark: "#A100FF",
      contrastText: "#fff",
    },
    ssh: {
      light: "#f7fffa",
      main: "#7500C0",
      dark: "#A100FF",
      contrastText: "#fff",
    },
    admin: {
      light: "#FFFFFF",
      main: "#A100FF",
      dark: "#000000",
    },
    fui: {
      light: "#f7faff",
      main: "#7500C0",
      dark: "#A100FF",
      contrastText: "#fff",
    },
    aws: {
      // light: "#fff6eb",
      main: "#A100FF",
      dark: "#A100FF",
      contrastText: "#fff",
    },
    ec2: {
      light: `${indigo[600]}`,
      main: "#7500C0",
      dark: "#A100FF",
      contrastText: "#fff",
      orange: "#7500C0",
    },
  },
  status: {
    pending: "#FF7800",
    stopped: "#E90D0D",
    running: "#48CB48",
  },
  background: {
    paper: "#616161",
  },
  buttons: {
    disabled: "rgb(158, 158, 158)",
    cancel: "#9e9e9e",
  },
  typography: {
    useNextVariants: true,
    mainColor: "#000000",
    fontFamily: "Graphik"
  },
  backgroundBorderGreys: {
    background: "#F2F2F2",
    border: "rgba(112, 112, 112, 0.5)",
    lightBorder: "#8B8F92",
  },
});

export default theme;
