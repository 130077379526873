//mywizard360-techarch-mycloud-client\src\components\ssh\SshRegistration\RegistrationForm.js
import React, { useState, useEffect, Fragment } from "react";
// import React, { useState, useRef, useEffect, Fragment } from "react";
// import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../redux/ssh";
import { closeSuccessOrFailureDialog } from "../../../redux/ssh/index";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  MenuItem,
  InputLabel,
  Grid,
  TextField,
  CircularProgress,
  Checkbox,
  Tooltip
} from "@material-ui/core";

import {
  StyledFormControl,
  StyledSelect,
  StyledSubmitButton,
} from "../SshStyles";
import { tokenValidation } from "../../../helper/customizedHooks";
import {
  StyledBanner,
  StyledResourceCard,
  NameTextField,
} from "./RegistrationStyles";
import {
  SmallHeader,
  LargeSubtitle,
  LargeHeader,
  ExtraLargeHeader,
  Subtext,
} from "../../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../../assets/index";
import FormResources from "./FormResources";
import PropTypes from "prop-types";
import SuccessfulOrFailedDialog from "./SuccessfulOrFailedDialog/SuccessfulOrFailedDialog";
import base from "../../../config";
import { headers } from "../../../helper/api";
import Axios from "axios";
import { fetchPresignedUrl } from "../../../redux/faq/index";

/**
 * The registration/update form of SSH
 * @author Isabel Sprengers
 * @version 2.1
 * @see Used in component: Ssh.js
 */

const GenerateKey = ({
  submitRegistration,
  submitSSHUpdate,
  enableMFA,
  mfaData,
  isRegistered,
  email,
  isSubmitLoading,
  isRegistrationSuccessful,
  history,
  sshConfig,
  openSuccessfulOrFailedDialog,
  closeSuccessOrFailureDialogAction,
  isRegistration,
  isRegistrationLoading,
  idToken,
  eid,
  saveSshPublicKeyAction,
  currentPublicKey,
  presignedUrls,
  presignedUrlIsLoading,
  fetchPresignedUrl,
  activeAccount,
}) => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    publicKey: "",
  });
  const [SSHUpdateValues, setSSHUpdateValues] = useState({
    publicKey: "",
  });
  const [isPublicKeyValid, setIsPublicKeyValid] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isPassphraseValid, setIsPassphraseValid] = useState(false);
  const [isEnabledRegisterButton, setIsEnableRegisterButton] = useState(true);
  const [isEnabledUpdateButton, setIsEnableUpdateButton] = useState(true);

  useEffect(() => {
    setIsEnableRegisterButton(
      isPublicKeyValid && isFirstNameValid && isLastNameValid && isPassphraseValid
        ? false
        : true
    );
    setIsEnableUpdateButton(isPublicKeyValid ? false : true);
  }, [
    email,
    isPublicKeyValid,
    isFirstNameValid,
    isLastNameValid,
    isPassphraseValid,
  ]);

  const handleChange = (field) => (e) => {
    e.preventDefault();
    setValues({ ...values, [field]: e.target.value });
    setSSHUpdateValues({ ...SSHUpdateValues, [field]: e.target.value });
    if (field === "publicKey") {
      setIsPublicKeyValid(checkPublicKey(e.target.value));
    }
    if (field === "firstName") {
      setIsFirstNameValid(checkName(e.target.value));
    }
    if (field === "lastName") {
      setIsLastNameValid(checkName(e.target.value));
    }
    if (field === "passphrase") {
      setIsPassphraseValid(checkName(e.target.value));
    }
  };

  //Check the public key
  const regex = new RegExp("^ssh-rsa .*?" + email + "$");
  const checkPublicKey = (input) => {
    return regex.test(input) ? true : false;
  };

  //Check the FirstName and LastName
  const checkName = (input) => {
    const nameStart = /^[A-Za-z-]+$/i;
    return nameStart.test(input) ? true : false;
  };

  const submitData = {
    first_name: values.firstName,
    last_name: values.lastName,
    public_key: values.publicKey,
    passphrase: values.passphrase,
    // confirmation: values.confirmation,
  };

  const handleSubmit = () => {
    tokenValidation();
    submitRegistration(submitData);
    // enableMFA(eid)
  };

  const submitDataSSHUpdate = {
    public_key: SSHUpdateValues.publicKey,
  };

  const handleSubmitSSHUpdate = () => {
    tokenValidation();
    submitSSHUpdate(submitDataSSHUpdate);
  };

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );


  const fetchFilePresignedUrl = (event) => {
    event.preventDefault();
    const urls = `${base.urls.apis.faq}/mfa_registration_userguide.pdf`;
    Axios.get(urls, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eid
      },
    }).then((res)=>{
      const data = res.data
      window.open(data[0], "_blank");
    }).catch((error) => {
      console.log('Error', error)
    })    
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <FormResources
            downloadSSHConfigURL={sshConfig}
            presignedUrls={presignedUrls}
            isLoading={presignedUrlIsLoading}
            fetchPresignedUrl={fetchPresignedUrl}
            email={email}
          />
        </Grid>
        
      </Grid>
      
    </div>
  );
};

GenerateKey.propTypes = {
  /**
   * Function that submit data for Registration Tab form
   */
  submitRegistration: PropTypes.func,
  /**
   * Boolean that confirms if the user is registered
   */
  isRegistered: PropTypes.bool,
  /**
   * Function that submit data for Access Tab form
   */
  submitSSHUpdate: PropTypes.func,
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Boolean used for check if the submit button was clicked
   */
  isSubmitLoading: PropTypes.bool,
  /**
   * Boolean that confirms if the registration of the user was successful
   */
  isRegistrationSuccessful: PropTypes.bool,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  sshConfig: PropTypes.string,
  /**
   * Function that enable mfa registration 
   * */
   enableMFA: PropTypes.func,
};

const MSTP = (state) => ({
  currentPublicKey: state.ssh.currentPublicKey,
  isLoading: state.ssh.isLoading,
  isSubmitLoading: state.ssh.isSubmitLoading,
  email: state.auth.userDetails.email,
  activeAccount: state.auth.activeAccount,
  isRegistrationSuccessful: state.ssh.isRegistrationSuccessful,
  openSuccessfulOrFailedDialog: state.ssh.openSuccessfulOrFailedDialog,
  isRegistration: state.home.isRegistration,
  isRegistrationLoading: state.home.isRegistrationLoading,
  idToken: state.auth.idToken,
  presignedUrls: state.faq.presignedUrls,
  presignedUrlIsLoading: state.faq.isLoading,
  mfaData: state.ssh.mfaData,
  eid: state.auth.userDetails.email
    ? state.auth.userDetails.email.split("@")[0]
    : "",
});

export default connect(MSTP, {
  submitRegistration: actions.submitRegistration,
  submitSSHUpdate: actions.submitSSHUpdate,
  closeSuccessOrFailureDialogAction: closeSuccessOrFailureDialog,
  saveSshPublicKeyAction: actions.saveSshPublicKey,
  fetchPresignedUrl: fetchPresignedUrl,
  enableMFA: actions.enableMFA,
})(withRouter(GenerateKey));
